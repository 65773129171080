import { BaseModel } from "./base.model";

export class CarouselSlide extends BaseModel {
  constructor(
    public title: {[lang:string]: string},
    public image?: string,
    public description?: {[lang:string]: string},
    public button_text?: {[lang:string]: string},
    public button_link?: {[lang:string]: string}
  ) {
    super();
  }
}
                                  