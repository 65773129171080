<template>
  <b-modal v-model="value" :title="title" ref="slide-modal" id="slide-modal">
    <b-form @submit.prevent="save">
      <SlideDetail v-if="type == 'slides'" :index="index" :slide="selected" :submitted="submitted"/>
      <ItemDetail v-if="type == 'items'" :index="index" :item="selected" :submitted="submitted"/>
    </b-form>
    <template #modal-footer>
      <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'"
      >
        {{ message }}
      </div>
      <b-button type="submit" @click="save()">{{ $t("save") }}</b-button>
      <b-button @click="value = false">{{ $t("close") }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import SlideDetail from "@/components/admin/home/SlideDetail.vue"
import ItemDetail from "@/components/admin/home/ItemDetail.vue"

export default {
  props: ["value", "selected", "object", "index", "title", "type"],
  components: {
    SlideDetail,
    ItemDetail
  },
  data() {
    return {
      message: "",
      submitted: false,
      successful: false,
    };
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    },
  },
  methods: {
    async save() {
      this.message = "";
      this.submitted = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        if (this.index !== null) {
          this.object[this.index] = this.selected;
          this.value = false;
        } else {
          this.object.push(this.selected);
          this.$emit("input", false);
        }
      });
    },
  },
};
</script>
