import { BaseModel } from "./base.model";

export class CardItem extends BaseModel {
  constructor(
    public type: string,
    public active: boolean,
    public title: {[lang:string]: string},
    public description?: {[lang:string]: string},
    public category?: {[lang:string]: string},
    public image?: string,
    public url?: string,
    public date?: string,
    public openBlank?: boolean,
  ) {
    super();
  }
}